import React from "react";
import NavBar from "./presentation/NavBar";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    siteYaml {
      advantages
      features
      processes
      contactUs
      clients
    }
  }
`;

export default function NavBarComponent() {
  const {
    siteYaml: { processes, advantages, features, clients, contactUs },
  } = useStaticQuery(query);

  const links = [
    ["processes", processes],
    ["advantages", advantages],
    ["features", features],
    ["testimonials", clients],
  ];

  return <NavBar links={links} contactUs={contactUs} />;
}
