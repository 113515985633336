import { useReducer, useEffect } from "react";

const isBrowser = () => typeof window !== "undefined";

const getWindowDistance = () => ({ value: window.scrollY });

/**
 * Takes in the new state and updates it while establishing whether an upward scroll has occurred
 * @param {object} state the previous state
 * @param {object} action the value of the recent call to dispatcher
 * @returns the updated state
 */
const reducer = ({ value: prev }, { value: curr }) => ({
  isUp: prev < curr,
  value: curr,
});

/**
 *
 * @returns true if the scroll direction is upwards, false otherwise
 */
export default function useScrollUp() {
  const [state, setWindowDistance] = useReducer(reducer, {
    isUp: false,
    value: isBrowser() ? getWindowDistance() : 0,
  });

  useEffect(() => {
    if (isBrowser()) {
      function handleScroll() {
        setWindowDistance(getWindowDistance());
      }
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return state.isUp;
}
