import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // MenuDivider,
  Stack,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import useScrollUp from "../../util/hooks/useScrollUp";

const createScrollTo = (id) => () =>
  document.getElementById(id).scrollIntoView();

const NavLink = ({ children, id = "" }) => (
  <Button
    px={2}
    py={2}
    rounded={"md"}
    colorScheme='brand'
    color='white'
    fontWeight='500'
    letterSpacing={"wide"}
    _hover={{
      bg: useColorModeValue("brand.200", "brand.700"),
    }}
    variant='ghost'
    onClick={createScrollTo(id)}
  >
    {children}
  </Button>
);

export default function NavBar({ links, contactUs }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isHidden = useScrollUp();

  return (
    <Box
      // bgGradient="linear(140deg, brand.200, brand.400)"
      bg='brand.300'
      position='fixed'
      h='max-content'
      width='100vw'
      zIndex='5'
      transition='top 150ms ease-in'
      top={{ base: isHidden && !isOpen ? -16 : 0, lg: 0 }}
    >
      <Flex
        h={16}
        px={4}
        alignItems={"center"}
        justifyContent={"space-between"}
        maxW='6xl'
        marginInline='auto'
      >
        <IconButton
          variant='ghost'
          size={"lg"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          colorScheme={"brand"}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          mr={2}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack
          spacing={8}
          w='100%'
          justifyContent='space-between'
          alignItems={"center"}
        >
          <Box w='10rem'>
            <Link to='/'>
              <StaticImage
                src='../../images/profiler-white-320.png'
                alt='ProfilerLive Logo'
              />
            </Link>
          </Box>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {links &&
              links.map(([link, linkText]) => (
                <NavLink key={link} id={link}>
                  {linkText}
                </NavLink>
              ))}
          </HStack>
        </HStack>
        <Button
          size={"sm"}
          px={6}
          ml={6}
          onClick={createScrollTo("contact")}
          colorScheme='brand'
          bgGradient='linear(to-br, brand.400, brand.500)'
          color='white'
          variant='solid'
        >
          {contactUs}
        </Button>
      </Flex>

      {isOpen && (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {links &&
              links.map(([link, linkText]) => (
                <NavLink key={link} id={link}>
                  {linkText}
                </NavLink>
              ))}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
